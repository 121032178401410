<template>
  <!-- 模板弹窗 -->
  <el-drawer
      title="配置模板"
      :close-on-click-modal="false"
      size="25%"
      :visible.sync="configTableVisible">
    <el-table
        :data="config.configTableDate"
        style="width: 100%">
      <el-table-column prop="tableId" label="表格标识" :show-overflow-tooltip="true" />
      <el-table-column prop="name" label="名称" :show-overflow-tooltip="true" />
      <el-table-column prop="remark" label="备注" :show-overflow-tooltip="true" />
      <el-table-column prop="defaultOrNot" label="状态" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <template>
            <el-switch
                v-model="scope.row.defaultOrNot"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="'1'"
                :inactive-value="'0'"
                @change="setDefaultOrNot(scope.row)">
            </el-switch>
          </template>
        </template>
      </el-table-column>
      <el-table-column fixed="right" align="center" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="deleteTabulation(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-drawer>
</template>

<script>
import html2canvas from 'html2canvas'
import * as myJsonClon from "@/utils/myJsonClone";
export default {
  data () {
    return {
      configTableVisible: false,
      config: {
        configTableId: null,
        configDefaultColumn:[],
        configTableDate: [],


        dialogConfigTableVisible: false,
      },

      // 视图排序
      selectedColumnIndex: null,
      dragging: false,
      startX: 0,
      startWidth: 0,
      currentColumnIndex: null
    }
  },
  components: {},
  created () {},
  computed: {},
  methods: {
    async init(configTableId) {
      // 重置
      this.config = this.$options.data().config

      // 业务
      this.config.configTableId = configTableId
      this.$http({
        url: this.$http.adornUrl('/tabulation/list/' + this.config.configTableId),
        method: 'get',
        params: this.$http.adornParams()
      }).then(async ({ data }) => {
        this.config.configTableDate = data
        this.configTableVisible = true
      })
    },

    // 设置默认值
    setDefaultOrNot (row) {
      let title = row.defaultOrNot == 0 ? '否' : '设置'
      this.$confirm('确定对【' + row.name + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/tabulation/defaultOrNot/' + row.id + '/' + row.tableId),
          method: 'PUT',
          params: {
            defaultOrNot: row.defaultOrNot == 0 ? 0 : 1
          }
        }).then(({ data }) => {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1000,
          })
          window.location.reload()
        })
      }).catch(() => {})
    },

    // 删除
    deleteTabulation (row) {
      this.$confirm('确定进行【删除】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/tabulation/${row.id}`),
          method: 'delete'
        }).then(({ data }) => {
          if (data) {
            this.$message.success('删除成功')
            if (row.defaultOrNot == 1) {
              window.location.reload()
            } else {
              this.$http({
                url: this.$http.adornUrl('/tabulation/list/' + this.config.configTableId),
                method: 'get',
                params: this.$http.adornParams()
              }).then(async ({ data }) => {
                this.config.configTableDate = data
              })
            }
          }
        })
      }).catch(() => { })
    },

  }
}
</script>

<style scoped>
</style>
